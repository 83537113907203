<template>
  <div>
    <v-rect v-if="!hidden"  :config="svRectConfig"></v-rect>
    <v-text  v-if="!hidden" :config="textConfig"></v-text>
    <v-text  v-if="!hidden && hint" :config="hintTextConfig"></v-text>
    <HighlightBar  v-if="!hidden" :startX="svHighlightInfo.start" :width="svHighlightInfo.width" :height="rarePathogenicSvsTrackHeight-2" :baseLineY="baseLineY+1"></HighlightBar>
    <!-- <HighlightBar v-if="showSecondaryHighlight" :startX="svHighlightInfo.start" :width="svHighlightInfo.width + 10" :height="rarePathogenicSvsTrackHeight" :baseLineY="baseLineY"></HighlightBar> -->
    <HighlightBar  v-if="!hidden" :startX="secondaryHighlightStart" :width="secondaryHighlightWidth" :height="rarePathogenicSvsTrackHeight" :baseLineY="baseLineY"></HighlightBar>
    <!-- <Tooltip :region="tooltipRegion" :text="hint"></Tooltip> -->

    <Legend v-if="!hidden && showLegend" :items="legendItems" :x="legendX" :y="legendY"></Legend>
    <RarePathogenicSV  :enableHightlighSameSamples="enableHightlighSameSamples" :tadInfo="tadInfo" v-for="g,idx in linedSVs.svs" :key="idx" :info="g" :baseLineY="baseLineY + g.line * svLineHeight + svTrackPaddingTop"></RarePathogenicSV>
    <v-line  :config="lineConfig"></v-line>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import RarePathogenicSV from "@/components/defaultAnalysis/tad/RarePathogenicSV.vue";
import Legend from "@/components/defaultAnalysis/tad/Legend.vue";
import HighlightBar from "@/components/defaultAnalysis/tad/HighlightBar.vue";

import { toScatedX } from "@/utils/tad.js";
// import Tooltip from "@/components/defaultAnalysis/tad/Tooltip.vue";
import { getTextSizeInPixel } from "@/utils/textUtils.js";

Vue.use(VueKonva);
export default {
  name: "RarePathogenicSVsTrack",
  components: { RarePathogenicSV, Legend, HighlightBar },
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    svs: {
      type: Array,
      default: () => [],
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    svHighlightInfo: {
      type: Object,
      required: true,
    },
    showLegend:{
      type: Boolean,
      default: true
    },
    title: {
      type: String, 
      required: true,
      
    },
    hint:{
      type: String, 
      default: null,

    },
    hidden:{
      type: Boolean,
      default: false
    },
    enableHightlighSameSamples:{
      type:Boolean,
      default: false
    }
  },
  data: () => ({
    legendItems: [
      {
      text:'DEL',
      fillColor: "#ff4462",
      borderStyle:null,
      type: 'square'
    },{
      text:'DUP',
      fillColor: "#E9A643",
      borderStyle:null,
      type: 'square'
    },{
      text:'INS',
      fillColor: "#4BAAd7",
      borderStyle:null,
      type: 'square'
    },{
      text:'INV',
      fillColor: "#417037",
      borderStyle:null,
      type: 'square'
    },{
      text:'TRN',
      fillColor: "#C585B7",
      borderStyle:null,
      type: 'square'
    },{
      text:'BND',
      fillColor: "#753939",
      borderStyle:null,
      type: 'square'
    },{
      text:'SNP',
      fillColor: "#4776a1",
      borderStyle:null,
      type: 'square'
    },{
      text:'DUP:TAND',
      fillColor: "#67bf56",
      borderStyle:null,
      type: 'square'
    }],
    svLineHeight: 18,
    svTrackPaddingTop: 25,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0
    },
    showSecondaryHighlight: false,
    highlightedSampleName:null,
    // secondaryHighlightStart: 0,
    // secondaryHighlightWidth: 0,
  }),
  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
      this.highlightedSampleName = prm?.sample || null
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
      this.highlightedSampleName = null
    }
  },
  mounted(){
    // this.$nextTick(()=>{
      setTimeout(()=>{
        // console.log('THIS!')
        this.$emit("onRarePathogenicSVsTrackHeight", this.rarePathogenicSvsTrackHeight);
      },10)
    // })
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },

  watch: {
    rarePathogenicSvsTrackHeight(val){
        // console.log('this====', val, this.linedSVs.lines.length)
        this.$emit("onRarePathogenicSVsTrackHeight", val);
    },
  },
  computed: {
    hintTextConfig() {
      return {
        x: 10 + getTextSizeInPixel(this, this.title + (this.highlightedSampleName && this.enableHightlighSameSamples? this.highlightedSampleName : '' ) , "normal 14px Roboto") * 0.90,
        y: this.baseLineY + 3,
        text: this.hint,
        // width: 100,
        fontSize: 11,
        // fontStyle: "bold",
        fill: "#00000060",
      };
    },
    tooltipRegion() {
      return {
        x: 4,
        y: this.baseLineY + 2,
        width: getTextSizeInPixel(this, this.title, "normal 13px Roboto") * 0.90,
        height: 15,
      };
    },
    secondaryHighlightStart(){
      return this.toX(this.secondaryHightlightInfo.start)
    },
    secondaryHighlightWidth(){
      return this.showSecondaryHighlight? this.secondaryHightlightInfo.widthInPx : 0
    },
    lineConfig(){
            return {
                x: 0,
                y: 0,
                points: [ this.toX(this.start)  , this.baseLineY + this.rarePathogenicSvsTrackHeight - 2, this.toX(this.end), this.baseLineY + this.rarePathogenicSvsTrackHeight -2],
                closed: true,
                stroke: '#00000020',
                strokeWidth: this.hidden? 0:1,
                opacity: 0.18,
                // fill: '#C585B7'
                dashEnabled: true,
                // dash: [ 3,3],
                fill: '#7dafdf'
            }
    },
    legendX() {
      return this.toX(this.tadInfo.max) - 420;
    },
    legendY() {
      return this.baseLineY + 6;
    },
    textConfig() {
      return {
        x: 4,
        y: this.baseLineY + 3,
        text: this.highlightedSampleName === null || !this.enableHightlighSameSamples ? this.title : this.title + " : " + this.highlightedSampleName,
        // width: 100,
        fontSize: 13,
        fontStyle: "bold",
        fill: "#7dafdf",
      };
    },
    linedSVs() {
      
      let gn = [...this.svs];
      const lines = [];
      gn.sort((a, b) => a.start - b.start).forEach((g) => {
        let added = false;
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].findIndex((l) => this.toX(g.start) - 3 < this.toX(l.end)) < 0) {
            // there is a coverage
            lines[i].push(g);
            g["line"] = i;
            g["color"] = this.legendItems.find(item => item.text == g.type)?.fillColor || 'black';// this.colors[g.type];
            added = true;
            break;
          }
        }
        if (!added) {
          lines.push([g]);
          g["line"] = lines.length - 1;
          g["color"] = this.legendItems.find(item => item.text == g.type)?.fillColor || 'black';// this.colors[g.type];
        }
      });

      return { svs: gn, lines: lines };
    },
    start() {
      return this.tadInfo.min;
    },
    end() {
      return this.tadInfo.max;
    },
    rarePathogenicSvsTrackHeight() {
      if (this.hidden){
        return 0
      } 
      return this.linedSVs.lines.length * this.svLineHeight + this.svTrackPaddingTop;
    },
    svRectConfig() {
      return { x: this.toX(this.start), y: this.baseLineY, width: this.toX(this.end) - this.toX(this.start), height: this.rarePathogenicSvsTrackHeight, strokeWidth: 0 };
    },
  },
};
</script>
