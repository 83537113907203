<template>
    <div>
    <v-line  ref="tad" :config="config" @mouseover="onMouseOver" @mouseout="onMouseOut"/>  
    <v-text :config="textConfig" ></v-text>
    <v-text :config="startTextConfig" ></v-text>
    <!-- <v-text :config="endTextConfig" ></v-text> -->
</div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import { toScatedX } from '@/utils/tad.js'
import {getTextSizeInPixel } from '@/utils/textUtils.js'

Vue.use(VueKonva);
export default {
    name:'Tad',
    props:{
        name:{
            type:String,
            default:null
        },
        start:{
            type:Number,
            default:0
        },
        end:{
            type:Number,
            default:0
        },
        color:{
            type: String,
            default: '#dddddd'
        },
        baseLineY:{
            type:Number,
            default:500
        }
        ,
        tadInfo:{
            type: Object,
            required:true,
        }
    },
    computed:{
        startTextConfig(){
            return {x: this.toX(this.start),y: this.baseLineY + 4, text: this.start, fontSize:12, fill: 'grey' }
        },
        textConfig(){
            return {x: this.toX(this.start) + (this.toX(this.end) - this.toX(this.start))/2 - getTextSizeInPixel(this, 13, this.name) -20  ,y: this.baseLineY - 15, text: this.name, fontSize:13, fontStyle:'bold', fill: 'brown' }
        },        
        endTextConfig(){
            return {x: this.toX(this.end) - String(this.end).length*8 , y: this.baseLineY + 4, text: this.end, fontSize:12, fill: 'grey' }
        },
        config(){
            const start = this.toX(this.start) 
            const end = this.toX(this.end)
            const apexX = start+ (end-start)/2
            const apexY = this.baseLineY -  (end-start) / 2*Math.tan(Math.PI/4)
            return {
                x: 0,
                y: 0,
                points: [start, this.baseLineY, end, this.baseLineY, apexX , apexY  ],
                closed: true,
                stroke: this.stroke,
                strokeWidth: 1,
                opacity: this.opacity,
                fill: this.color 
            } 
        }
    },
    data:()=>({
        opacity: 0.6,
        stroke: 'black'
        }),
    methods:{
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
        onMouseOut(){
            this.opacity = 0.6
        },
        onMouseOver(){
            this.opacity = 0.7
        }

    }

}   
</script>