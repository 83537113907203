<template>
  <div>
    <v-dialog v-if="isDialog" v-model="showDialog" persistent>
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div class="text-h5">Demonstration of {{ variantId }}</div>
            <div>
              <v-btn color="secondary" text @click="close()"> Close </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <TadChart v-if="tads.length > 0" :chromosome="chromosome" :tads="tads" :geneEnhancers="geneEnhancers" :mutations="sv" :dynamicTracks="dynamicTracks"></TadChart>
          <v-btn class="mx-auto" color="primary" width="300px" @click="onApply">APPLY</v-btn>
          <TadChart v-if="isApplied" :chromosome="chromosome" :tads="modifiedTads" :geneEnhancers="modifiedGeneEnhancers" :mutations="sv" :dynamicTracks="modifiedDynamicTracks"></TadChart>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-else>
      <v-tabs v-model="tab">
        <v-tab active-class="grey lighten-4">REFERENCE REGULOME</v-tab>
        <v-tab active-class="grey lighten-4">PATIENT REGULOME</v-tab>
        <v-tab active-class="grey lighten-4">CUBAN IMAGE</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item class="grey lighten-4 pa-1">
          <div style="min-height: 800px">
            <TadChart
              ref="refrenceTad"
              :variantId="variantId"
              v-if="tads.length > 0"
              :chromosome="chromosome"
              :tads="tads"
              :geneEnhancers="geneEnhancers"
              :mutations="sv"
              :isoforms="coolboxData.transcripts"
              :dynamicTracks="dynamicTracks"
              :rarePathogenicSvs="rarePathogenicSvs"
              :pedigreeSvs="pedigreeSvs"
              :selectedUsersGenes="selectedUsersGenes"
            ></TadChart>
          </div>
        </v-tab-item>
        <v-tab-item class="grey lighten-4 pa-1">
          <TadChart :variantId="variantId" :chromosome="chromosome" :tads="modifiedTads" :geneEnhancers="modifiedGeneEnhancers" :mutations="modifiedSv" :dynamicTracks="modifiedDynamicTracks" :pedigreeSvs="{}"></TadChart>
        </v-tab-item>
        <v-tab-item class="grey lighten-4 pa-1">
          <div v-if="cubanImageData !== null" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <div class="text-h6 ml-4 mt-4">
                <v-icon color="primary">mdi-chart-timeline</v-icon> Genome View Snapshot 
                <a class="caption" :href="cubanHelpUrl" target="_black"> Learn about Cuban Images <v-icon x-small class="mb-1" color="primary">mdi-open-in-new</v-icon> </a>
              </div>
              <div v-if="variantMatcherField !== null" class="ml-auto mx-4 mt-4">
                <div class="caption mb-1">Do you think this variant is invalid? Check <Help section="UI" lookupKey="VARIANT_MATCHER" type="text"> Variant Matcher </Help></div>
                <v-btn
                  small
                  color="primary"
                  class="grey lighten-3"
                  style="border: 1px solid #d9d9d9 !important"
                  :style="showMatchedVariants ? ' border-bottom: 0px solid red !important; border-radius: 4px 4px 0px 0px !important;' : ''"
                  text
                  outlined
                  width="334px"
                  @click="showMatchedVariants = !showMatchedVariants"
                  >Variant Matcher <v-icon>{{ showMatchedVariants ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon></v-btn
                >
              </div>
            </div>

            <VariantMatcher v-if="variantMatcherField !== null" v-show="showMatchedVariants" :cohortId="cohortId" :datasetId="selectedDatabaseId" :idField="idField" :variantId="variantId"></VariantMatcher>
            <img class="mt-2 mx-4" :src="cubanImageData" />
          </div>
          <div v-else class="my-8 mx-8 caption grey--text">No Image</div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import TadChart from "@/components/defaultAnalysis/tad/TadChart.vue";
import { getVariantTads } from "@/api/user/dataset";
import { getVariantModifiedTads } from "@/api/user/dataset";
import { getCubanImage } from "@/api/user/dataset.js";
import Help from "@/components/Common/Help.vue";
import VariantMatcher from "@/components/VariantMatcher.vue";
// import { getData } from "@/api/user/dataset";
// import { getDatasetFields } from "@/api/dataset";
import { getDatasetFields } from "@/api/dataset";

export default {
  name: "Tadcoolbox",
  components: { TadChart, Help, VariantMatcher },
  props: {
    cohortId: {
      type: Number,
      default: 1,
      // required: true,
    },
    selectedDatabaseId: {
      type: Number,
      default: 1,
      // required: true,
    },
    variantId: {
      type: String,
      // default: 'chr17-44524913-DEL-176'
      default: null,
      // required: true,
    },
    isDialog: {
      type: Boolean,
      default: true,
    },
    sv: {
      type: Array,
      default: () => [],
    },
    differentiallyExpressedGenes: {
      type: Array,
      default: () => [],
    },
    selectedUsersGenes: {
      type: Array,
      required: true,
    },
    chromosome: {
      type: Object,
      default: () => ({
        id: null,
        start: 0,
        end: 0,
      }),
      // required: true,
    },
  },
  data: () => ({
    showMatchedVariants: false,
    tab: null,
    panel: [0],
    isApplied: false,
    showDialog: true,
    idField: null,
    variantMatcherField: null,
    coolboxModifedData: {
      tads: [],
      connections: [],
      dynamic_tracks: [],
      enhancers: [],
      genes: [],
      sv: null,
    },
    coolboxData: {
      tads: [],
      connections: [],
      dynamic_tracks: [],
      enhancers: [],
      genes: [],
      transcripts: [],
      rare_pathogenic_svs: [],
      pedigree_svs: {},
    },
    cubanImageData: null,
    // modifiedSv:[],
    hasError: false,
    tadsJsonSelectedMode: "tree",
    // selectedMutation: [],
    // chromosome:
    //   {
    //     id:'chr1',
    //     start:12,
    //     end:32121653
    //   },
    // initTads: [
    //   {
    //     id: "TAD.fibroblast.2491",
    //     start: 100000,
    //     end: 400000,
    //     chromosome: "chr17",
    //   },
    //   {
    //     id: "TAD.fibroblast.2222",
    //     start: 400000,
    //     end: 600000,
    //     chromosome: "chr17",
    //   }
    // ],
    // geneEnhancers:{
    //   elements:[],
    //   connections:[]
    // },
    // tads: [],
  }),
  methods: {
    getImage() {
      return this.$refs.refrenceTad.getImage();
    },
    onApply() {
      const _this = this;

      getVariantModifiedTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
        // res.tads.map(t => { t['id'] = t.name, t['chromosome'] = t.chr})
        // res.genes.map(g => { g['id'] = g.name, g['chromosome'] = g.chr, g['dir'] = g.direction, g['dir'] = g.direction})
        _this.coolboxModifedData = res;
        _this.isApplied = true;
        _this.panel = [1];

        // console.log(res)
      });
    },
    close() {
      this.showDialog = false;
      this.$emit("onClose");
    },
    loadRegolume() {
      const _this = this;
      getDatasetFields(_this.selectedDatabaseId, (res) => {
        _this.idField = res.find((item) => item.title == "ID")?.name || null;
        _this.variantMatcherField = res.find((item) => item.title == "VARIANTMATCHER")?.name || null;
        // _this.variantMatcherField = "fim"
      });
      getVariantTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
        _this.coolboxData = res;
      });
      getVariantModifiedTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
        // res.tads.map(t => { t['id'] = t.name, t['chromosome'] = t.chr})
        // res.genes.map(g => { g['id'] = g.name, g['chromosome'] = g.chr, g['dir'] = g.direction, g['dir'] = g.direction})
        _this.coolboxModifedData = res;
        _this.isApplied = true;
        _this.panel = [1];

        // console.log(res)
      });
      getCubanImage(
        this.cohortId,
        this.selectedDatabaseId,
        this.variantId,
        (res) => {
          _this.cubanImageData = URL.createObjectURL(res);
        },
        () => {
          _this.cubanImageData = null;
        }
      );
    },
  },
  computed: {
    cubanHelpUrl(){
      return "#/user/help/cubanplot"
    },
    rarePathogenicSvs() {
      return this.coolboxData.rare_pathogenic_svs;
    },
    pedigreeSvs() {
      return this.coolboxData.pedigree_svs;
    },
    tads() {
      // console.log("tads:171", this.coolboxData.tads);
      return this.coolboxData.tads.map((item) => {
        let t = item;
        t["id"] = item.name;
        t["chromosome"] = item.chr;
        return t;
      });
    },
    geneEnhancers() {
      // console.log("geneEnhancers:180", this.coolboxData.genes);
      let genes = this.coolboxData.genes.map((item) => {
        let g = item;
        g["id"] = g.name;
        g["chromosome"] = g.chr;
        g["dir"] = g.direction;
        g["type"] = "GENE";
        g["userSelected"] = this.selectedUsersGenes.indexOf(g.name.toUpperCase()) >= 0;
        g["differentiallyExpressed"] = this.differentiallyExpressedGenes.indexOf(g.name.toUpperCase()) >= 0;
        return g;
      });
      let enhancers = this.coolboxData.enhancers.map((item) => {
        let e = item;
        e["id"] = e.name;
        e["chromosome"] = e.chr;

        e["dir"] = e?.direction || null;
        e["type"] = "RE";
        return e;
      });
      let connections = this.coolboxData.connections.map((c) => {
        return { from: c.enhancer, to: c.gene, color: 3 };
      });
      return { elements: genes.concat(enhancers), connections: connections };
    },
    dynamicTracks() {
      const tracks = Array.from(new Set(this.coolboxData.dynamic_tracks.map((item) => item.track_name)));
      // const tracks = t.concat(t)
      return tracks.map((t) => {
        let x = { trackName: t };
        x["instants"] = this.coolboxData.dynamic_tracks
          .filter((item) => item.track_name == t)
          .map((i) => {
            return {
              name: i.element_name,
              start: i.start,
              end: i.end,
              color: i.color,
              dir: i.direction,
              info: i.info,
            };
          });
        return x;
      });
    },
    modifiedTads() {
      return this.coolboxModifedData.tads.map((item) => {
        let t = item;
        t["id"] = item.name;
        t["chromosome"] = item.chr;
        return t;
      });
    },
    modifiedGeneEnhancers() {
      let genes = this.coolboxModifedData.genes.map((item) => {
        let g = item;
        g["id"] = g.name;
        g["chromosome"] = g.chr;
        g["dir"] = g.direction;
        g["type"] = "GENE";
        return g;
      });
      let enhancers = this.coolboxModifedData.enhancers.map((item) => {
        let e = item;
        e["id"] = e.name;
        e["chromosome"] = e.chr;

        e["dir"] = e?.direction || null;
        e["type"] = "RE";
        return e;
      });
      let connections = this.coolboxModifedData.connections.map((c) => {
        return { from: c.enhancer, to: c.gene, color: 3 };
      });
      return { elements: genes.concat(enhancers), connections: connections };
    },
    modifiedDynamicTracks() {
      const tracks = Array.from(new Set(this.coolboxModifedData.dynamic_tracks.map((item) => item.track_name)));
      return tracks.map((t) => {
        let x = { trackName: t };
        x["instants"] = this.coolboxModifedData.dynamic_tracks
          .filter((item) => item.track_name == t)
          .map((i) => {
            return {
              name: i.element_name,
              start: i.start,
              end: i.end,
              color: i.color,
              dir: i.direction,
              info: i.info,
            };
          });
        return x;
      });
    },
    modifiedSv() {
      let x = this.coolboxModifedData.sv;
      if (!x) {
        return [];
      }
      x["isModified"] = true;
      if (Object.keys(x).indexOf("id_field") > 0) {
        x["id"] = x["id_field"];
      }
      return [x];
    },
  },
  watch: {
    variantId() {
      this.loadRegolume();
    },
  },
  created() {
    this.loadRegolume();
  },
};
</script>
<style scoped>
.tadBody {
  background-color: #f5f5f5;
  border-bottom: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 0 0 8px 8px;
}
.tadHeader {
  border-top: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 8px 8px 0 0;
}
</style>