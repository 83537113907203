<template>
  <div ref="container" class="mx-auto pa-2 d-flex flex-column" style="width: 100%">
    <div class="ml-auto mt-n2 d-flex flex-row">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="onResetZoom()"> <v-icon>mdi-magnify-scan</v-icon></v-btn>
        </template>
        <span>Reset Zoom</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="onExportAsPng"> <v-icon>mdi-download</v-icon></v-btn>
        </template>
        <span>Download as PNG</span>
      </v-tooltip>
      <v-tooltip top >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">mdi-help</v-icon>
        </template>
        <span>Hold Ctrl to enable Sticky-Tooltip mode. It helps you to:</span>
        <ul>
          <li>See multiple tooltips at the same time</li>
          <li>Drag and move tooltips </li>
        </ul>      </v-tooltip>
    </div>
    <v-stage ref="stage" :config="stageSize" @mousedown="onZoomStarted" @mouseup="onZoomEnded" @mousemove="onZoomSelecting">
      <v-layer>
        <v-rect :config="backgroundRectConfig"></v-rect>
      </v-layer>
      <v-layer>
        <!-- <v-rect ref="svShadow" :config="mutationHighlightRectConfig"></v-rect> -->
        <Chromosome :spec="chromosomeInfo" :baseLineY="0"></Chromosome>
        <Tad :tadInfo="tadInfo" v-for="tad in tads" :key="tad.id" :start="tad.start" :name="tad.name" :end="tad.end" :color="tadColor" :baseLineY="baseLineY" />
        <Axis :tadInfo="tadInfo" :baseLineY="axisBaseline"></Axis>
        <GeneTrack :legendItems="legendItems" :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="geneTrackBaseline" :genes="allGenes" :elements="allREs" :connections="allConnections" @onGeneTrackHeight="onGeneTrackHeight"></GeneTrack>
        <MutationTrack
          trackName="Structural Variant"
          :tadInfo="tadInfo"
          v-if="mutations.length > 0"
          :baseLineY="baseLineY + geneTrackHeight"
          :mutations="mutations"
          v-on:onMutationMouseOver="onMutationMouseOver"
          v-on:onMutationMouseOut="onMutationMouseOut"
          @onSvBar="onSvBar"
        ></MutationTrack>
        <RarePathogenicSVsTrack
          hint="( NOT in public databases, Dicast Score ≥ 0.4, GT ≠ 0/0 )"
          :hidden="pedigreeSvs.proband === undefined"
          key="probandTrack"
          title="Proband SVs"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="rareProbandPathogenicSVsTrackBaseLine"
          :svs="pedigreeSvs?.proband?.SVs || []"
          @onRarePathogenicSVsTrackHeight="onRareProbandPathogenicSVsTrackHeight"
        ></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack
          key="motherTrack"
          :hidden="pedigreeSvs.mother === undefined"
          :showLegend="false"
          :title="pedigreeSvs.mother ? `Mother (${pedigreeSvs.mother.sample_id}): ${pedigreeSvs.mother.status}` : ''"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="rareMotherPathogenicSVsTrackBaseLine"
          :svs="pedigreeSvs?.mother?.SVs || []"
          @onRarePathogenicSVsTrackHeight="onRareMotherPathogenicSVsTrackHeight"
        ></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack
          key="fatherTrack"
          :hidden="pedigreeSvs.father === undefined"
          :showLegend="false"
          :title="pedigreeSvs.father ? `Father (${pedigreeSvs.father.sample_id}): ${pedigreeSvs.father.status}` : ''"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="rareFatherPathogenicSVsTrackBaseLine"
          :svs="pedigreeSvs?.father?.SVs || []"
          @onRarePathogenicSVsTrackHeight="onRareFatherPathogenicSVsTrackHeight"
        ></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack 
          key="cohortTrack"
          :enableHightlighSameSamples="true"
          :showLegend="false"
          title="Rare Cohort SVs ⓘ"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="rareCohortPathogenicSVsTrackBaseLine"
          :svs="rarePathogenicSvs"
          @onRarePathogenicSVsTrackHeight="onRareCohortPathogenicSVsTrackHeight"
        ></RarePathogenicSVsTrack>

        <TrackSeparator :tadInfo="tadInfo" :y="dynamicTrackBaseLine - 2"></TrackSeparator>

        <FishboneTrack ref="FishboneTrack" :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="fishboneTrackBaseline" :isoforms="canonicalIsoforms" :sv="selectedMutation" @onFishboneTrackHeight="onFishboneTrackHeight"></FishboneTrack>
    <v-text :config="dynamicTrackTitleConfig"></v-text>
        <DynamicTrack :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" v-for="(track, index) in dynamicTracks" :key="track.name" :baseLineY="dynamicTrackBaseLine + index * 51" :trackInfo="track"></DynamicTrack>
      </v-layer>
      <v-layer>
        <v-rect :config="zoomAreaRectConfig"></v-rect>
      </v-layer>

      <!-- <v-layer ref="dragLayer"></v-layer> -->
    </v-stage>
    <!-- <div v-if="mutations.length > 0" class="d-flex flex-row">
        <v-btn :disabled="selectedMutations.length == 0" class="mx-auto"  width="200px" @click="onApply">Apply</v-btn>
    </div> -->
  </div>
</template>
<script>
// const height = window.innerHeight*0.85;
// const height = 900;
import Tad from "@/components/defaultAnalysis/tad/Tad.vue";
import Chromosome from "@/components/defaultAnalysis/tad/Chromosome.vue";
import Axis from "@/components/defaultAnalysis/tad/Axis.vue";
import GeneTrack from "@/components/defaultAnalysis/tad/GeneTrack.vue";
import MutationTrack from "@/components/defaultAnalysis/tad/MutationTrack.vue";
import DynamicTrack from "@/components/defaultAnalysis/tad/DynamicTrack.vue";
import FishboneTrack from "@/components/defaultAnalysis/tad/Transcripts/FishboneTrack.vue";
import RarePathogenicSVsTrack from "@/components/defaultAnalysis/tad/RarePathogenicSVsTrack.vue";
import TrackSeparator from "@/components/defaultAnalysis/tad/TrackSeparator.vue";

import { toScatedX } from "@/utils/tad.js";

export default {
  name: "TadChart",
  components: { Tad, Axis, GeneTrack, MutationTrack, Chromosome, DynamicTrack, FishboneTrack, RarePathogenicSVsTrack, TrackSeparator },
  // components: { Tad , Axis, GeneTrack, MutationTrack, DynamicTrack},
  props: {
    tads: {
      type: Array(),
      default: () => [],
    },
    chromosome: null,
    geneEnhancers: null,
    mutations: {
      type: Array(),
      default: () => [],
    },
    dynamicTracks: {
      type: Array(),
      default: () => [],
    },
    isoforms: {
      type: Array(),
      default: () => [],
    },
    rarePathogenicSvs: {
      type: Array(),
      default: () => [],
    },
    pedigreeSvs: {
      type: Object,
      default: () => {},
    },
    variantId: {
      type: String,
      // default: 'chr17-44524913-DEL-176'
      default: null,
      // required: true,
    },
  },

  data() {
    return {
      legendItems: [
        {
          text: "User Filtered",
          // fillColor: null,
          fillColor: null,
          borderColor: "green",
          borderStyle: "dashed",
          type: "line",
        },
        {
          text: "Associated with an Enhancer",
          // fillColor: null,
          fillColor: null,
          borderColor: "grey",
          borderStyle: "solid",
          type: "line",
        },
        {
          text: "Hit by SV",
          // fillColor: null,
          fillColor: "#ff0000",
          borderColor: "black",
          borderStyle: "solid",
          type: "line",
        },
        {
          text: "Differentially Expressed (q-value < 0.05)",
          // fillColor: null,
          fillColor: null,
          borderColor: "blue",
          borderStyle: "solid",
          type: "hatched",
        },
        {
          text: "HPO-Associated",
          fillColor: "#ff7575", //#10
          borderColor: null,
          borderStyle: null,
          type: "square",
        },
        {
          text: "Provided in Background",
          fillColor: "#e60049", //#2
          borderColor: null,
          borderStyle: null,
          type: "square",
        },
        {
          text: "Others",
          fillColor: "#b3d4ff", //#0
          borderColor: null,
          borderStyle: null,
          type: "square",
        },
      ],
      width: 0,
      mutationHighlightShow: false,
      geneTrackHeight: 40,
      fishboneTrackHeight: 50,
      rareCohortPathogenicSVsTrackHeight: 25,
      rareProbandPathogenicSVsTrackHeight: 25,
      rareMotherPathogenicSVsTrackHeight: 0,
      rareFatherPathogenicSVsTrackHeight: 0,
      dynamicTrackTitleConfigHeight:30,
      // selectedMutation: null,
      // minMaxInfo: null,
      //COLORS from: https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
      colors: ["#b3d4ff", "#d0d0d0", "#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#00bfa0", "#ff7575"],
      tadColor: "#ff4d4d",
      mutationTrackHeight: 46,
      svHighlightInfo: {
        start: 0,
        width: 0,
      },
      isZooming: false,
      zoomAreaRect: {
        end: 0,
        start: 0,
      },
      currentMinMax: null,
    };
  },
  mounted() {
    this.width = this.$refs.container.clientWidth * 0.99;
    // console.log('MOUNTED', this.currentMinMax)
  },

  methods: {
    onResetZoom() {
      this.currentMinMax = this.minMax;
    },
    onZoomStarted(e) {
      if (!e.evt.ctrlKey && e.evt.button == 0) {
        this.isZooming = true;

        this.zoomAreaRect.start = e.currentTarget.getPointerPosition().x;
        this.zoomAreaRect.end = e.currentTarget.getPointerPosition().x;
      }
    },
    onZoomEnded() {
      if (this.isZooming) {
        this.isZooming = false;
        if (Math.abs(this.zoomAreaRect.start - this.zoomAreaRect.end) > 4) {
          if (this.currentMinMax == null) {
            this.currentMinMax = this.minMax;
          }
          const scale = (this.currentMinMax.mx - this.currentMinMax.mn) / this.width;
          const min = this.currentMinMax.mn + Math.round(Math.min(this.zoomAreaRect.start, this.zoomAreaRect.end) * scale);
          const max = this.currentMinMax.mn + Math.round(Math.max(this.zoomAreaRect.start, this.zoomAreaRect.end) * scale);
          this.currentMinMax = {
            mn: min,
            mx: max,
          };
        }
      }
    },
    onZoomSelecting(e) {
      if (this.isZooming) {
        this.zoomAreaRect.end = e.currentTarget.getPointerPosition().x;
      }
    },

    onSvBar(start, width) {
      this.svHighlightInfo.start = start;
      this.svHighlightInfo.width = width;
    },
    getImage() {
      let stage = this.$refs.stage.getNode();
      const header = "data:image/png;base64,";
      return stage.toDataURL({ pixelRatio: 1.5 }).slice(header.length);
      // return 'HI'
    },
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onMutationMouseOver() {},
    onMutationMouseOut() {},
    onGeneTrackHeight(val) {
      this.geneTrackHeight = val + 92;
    },
    onFishboneTrackHeight(val) {
      this.fishboneTrackHeight = val;
    },
    onRareCohortPathogenicSVsTrackHeight(val) {
      // console.log('this.rareCohortPathogenicSVsTrackHeight',this.rareCohortPathogenicSVsTrackHeight)
      this.rareCohortPathogenicSVsTrackHeight = val;
    },
    onRareProbandPathogenicSVsTrackHeight(val) {
      // console.log('this.rareProbandPathogenicSVsTrackHeight',this.rareProbandPathogenicSVsTrackHeight)
      this.rareProbandPathogenicSVsTrackHeight = val;
    },
    onRareMotherPathogenicSVsTrackHeight(val) {
      // console.log('this.raremotherPathogenicSVsTrackHeight',this.rareMotherPathogenicSVsTrackHeight)
      this.rareMotherPathogenicSVsTrackHeight = val;
    },
    onRareFatherPathogenicSVsTrackHeight(val) {
      // console.log('this.rareFatherPathogenicSVsTrackHeight',this.rareFatherPathogenicSVsTrackHeight)
      this.rareFatherPathogenicSVsTrackHeight = val;
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    onExportAsPng() {
      let stage = this.$refs.stage.getNode();
      var dataURL = stage.toDataURL({ pixelRatio: 2 });
      this.downloadURI(dataURL, this.variantId + ".png");
    },
  },
  computed: {
    dynamicTrackTitleConfig(){
      return {
        x: 4,
        y:  this.dynamicTrackBaseLine - this.dynamicTrackTitleConfigHeight +12 ,
        width: 200,
        text: 'Epigenetic Information',
        fontSize: 13,
        fill: "#C585B7",
        align: "left",
        fontStyle: 'bold'
      };
    },
    zoomAreaRectConfig() {
      return {
        x: Math.min(this.zoomAreaRect.end, this.zoomAreaRect.start),
        y: 0,
        width: this.isZooming ? Math.abs(this.zoomAreaRect.end - this.zoomAreaRect.start) : 0,
        height: this.stageSize.height,
        stroke: "#0393A8",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: this.isZooming ? 0.1 : 0,
        // fill: this.colors[this.info.type],
        fill: "#0393A810",
      };
    },
    dynamicTrackBaseLine() {
      // console.log(this.baseLineY , this.geneTrackHeight , this.mutationTrackHeight , this.rarePathogenicSVsTrackHeight)
      return (
        this.baseLineY + this.geneTrackHeight + this.mutationTrackHeight + this.rareCohortPathogenicSVsTrackHeight + 1 + this.rareProbandPathogenicSVsTrackHeight + this.rareMotherPathogenicSVsTrackHeight + this.rareFatherPathogenicSVsTrackHeight + this.fishboneTrackHeight +  this.dynamicTrackTitleConfigHeight
      );
    },
    fishboneTrackBaseline() {
      return (
        this.baseLineY +
        this.mutationTrackHeight +
        this.geneTrackHeight +
        // this.dynamicTracks.length * 52 +
        this.rareCohortPathogenicSVsTrackHeight +
        1 +
        this.rareProbandPathogenicSVsTrackHeight +
        this.rareMotherPathogenicSVsTrackHeight +
        this.rareFatherPathogenicSVsTrackHeight
      );
    },
    axisBaseline() {
      return this.baseLineY + 40;
    },
    geneTrackBaseline() {
      return this.baseLineY + 90;
    },

    rareProbandPathogenicSVsTrackBaseLine() {
      return this.baseLineY + this.geneTrackHeight + this.mutationTrackHeight;
      // return this.rareCohortPathogenicSVsTrackBaseLine + this.rareCohortPathogenicSVsTrackHeight
    },
    rareMotherPathogenicSVsTrackBaseLine() {
      return this.rareProbandPathogenicSVsTrackBaseLine + this.rareProbandPathogenicSVsTrackHeight;
    },
    rareFatherPathogenicSVsTrackBaseLine() {
      return this.rareMotherPathogenicSVsTrackBaseLine + this.rareMotherPathogenicSVsTrackHeight;
    },
    rareCohortPathogenicSVsTrackBaseLine() {
      return this.rareFatherPathogenicSVsTrackBaseLine + this.rareFatherPathogenicSVsTrackHeight;
    },

    chromosomeInfo() {
      return {
        id: this.chromosome.id,
        start: this.tadInfo.min,
        end: this.tadInfo.max,
      };
    },
    selectedMutation() {
      if (this.mutations.length > 0) {
        return this.mutations[0];
      } else {
        return null;
      }
    },
    tadInfo() {
      let min = 0;
      let max = 0;
      if (this.currentMinMax != null) {
        // console.log(scale)
        min = this.currentMinMax.mn;
        max = this.currentMinMax.mx;
        // console.log(min,max)
      } else {
        min = this.minMax.mn;
        max = this.minMax.mx;
      }
      return {
        min: min,
        max: max,
        scale: this.width / (max - min),
      };
    },
    backgroundRectConfig() {
      return {
        x: 0,
        y: 0,
        width: this.stageSize.width,
        height: this.stageSize.height,
        // stroke: "black",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: 0,
        // fill: this.colors[this.info.type],
        fill: "#f5f5f5",
      };
    },

    mutationHighlightRectConfig() {
      let minWidth = 3;
      if (this.selectedMutation.isModified && this.selectedMutation.type == "DEL") {
        minWidth = 1;
      }
      return {
        x: this.toX(this.selectedMutation.start),
        y: this.baseLineY + 40,
        zIndex: 1,
        width: Math.max(this.toX(this.selectedMutation.end) - this.toX(this.selectedMutation.start), minWidth),
        height: this.geneTrackHeight + this.dynamicTracks.length * 55 + this.fishboneTrackHeight + 30 + this.rareCohortPathogenicSVsTrackHeight,
        stroke: "black",
        strokeWidth: 0,
        fill: "#55555540",
      };
    },
    stageHeight() {
      const h = this.dynamicTrackBaseLine + this.dynamicTracks.length * 52
      return h;
    },

    maxTadHeights() {
      const tadLength = this.tads.map((item) => this.toX(item.end) - this.toX(item.start));
      const maxTads = Math.max(...tadLength);
      return maxTads / 1.45 + 40;
    },

    stageSize() {
      return {
        width: this.width + 30,
        height: this.stageHeight,
      };
    },
    normalizationFactor() {
      // console.log(this.minMax)
      return this.width / (this.minMax.mx - this.minMax.mn);
    },
    minMax() {
      let ends = this.tads.map((item) => item.end);
      ends = this.geneEnhancers.elements.map((item) => item.end).concat(ends);
      this.dynamicTracks.forEach((tr) => {
        tr.instants.forEach((ins) => ends.push(ins.end));
      });

      let starts = this.tads.map((item) => item.start);
      starts = this.geneEnhancers.elements.map((item) => item.start).concat(starts);
      this.dynamicTracks.forEach((tr) => {
        tr.instants.forEach((ins) => starts.push(ins.start));
      });

      const mx = ends.reduce((a, b) => Math.max(a, b), 0);
      const mn = starts.reduce((a, b) => Math.min(a, b), +Infinity);

      return { mn: mn, mx: mx };
    },
    baseLineY() {
      const heights = this.tads.map((item) => ((item.end - item.start) / 2) * Math.tan(Math.PI / 4));
      return this.normalizationFactor * Math.max(...heights) + 50;
    },
    allConnections() {
      return this.geneEnhancers.connections.map((cn) => {
        const el = this.geneEnhancers.elements.find((el) => el.id == cn.from);
        const gn = this.geneEnhancers.elements.find((gn) => gn.id == cn.to);
        const r = {
          from: el.start + (el.end - el.start) / 2,
          fromId: el.id,
          to: gn.dir == "+" ? gn.start : gn.end, //+ (gn.end - gn.start) / 2,
          toId: gn.id,
          color: cn.color,
          colorStr: this.colors[cn.color % this.colors.length],
        };
        return r;
      });
    },
    allGenes() {
      return this.geneEnhancers.elements
        .filter((item) => item.type == "GENE")
        .map((item) => {
          item.colorStr = this.colors[item.color % this.colors.length];
          item.isHit = this.selectedMutation.end >= item.start && this.selectedMutation.start <= item.end;
          return item;
        });
    },
    allREs() {
      return this.geneEnhancers.elements
        .filter((item) => item.type == "RE")
        .map((item) => {
          item.colorStr = this.colors[item.color % this.colors.length];
          item.isHit = this.selectedMutation.end >= item.start && this.selectedMutation.start <= item.end;
          return item;
        });
    },
    canonicalIsoforms() {
      return this.isoforms.filter(item => item.canonical == true || item.canonical == undefined);
    },
  },
};
</script>

<style scoped>
.borderedBox {
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(177, 177, 177);
  border-radius: 8px;
}
</style>